import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './resume.scss';
import { positions } from '../data/positions';
import { competencies } from '../data/competencies';
import SEO from '../components/seo';

const ResumePage = () => (
  <div className="resume h-100 d-flex flex-column">
    <SEO title="Luke Ulrich | Resume" />
    <div className="shadow">
      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-baseline">
            <h1 className="font-brand mb-0">Luke Ulrich</h1>
            <div className="d-flex flex-row connections">
              <a href="https://github.com/lukeulrich" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'github']} size="2x" />
              </a>
              <a href="https://www.linkedin.com/in/luke-ulrich" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" className="ml-3" />
              </a>
            </div>
          </div>
        </div>

        <section className="row">
          <div className="col subline">
            <h2 className="mb-0">Software Expert, Bioinformatician, Entrepreneur</h2>
            <p className="font-italic">Full stack, front-end specialist</p>
          </div>
        </section>
      </div>
    </div>

    <div className="overflow-auto pt-4">
      <div className="container mb-3">
        <div className="row d-flex flex-column h-100">
          <div className="col">
            <section className="overview">
              <p>Software architect with the proven experience to produce high-value results and lead teams of professionals from concept to commercially viable software products. Accomplished full stack developer and business leader that adds value at all layers of an organization.</p>
            </section>

            <section className="experience">
              <h3>Experience</h3>
              {positions.map((position, i) => {
                let company = null;
                if (position.company) {
                  company = position.company;
                  if (position.companyURL) {
                    company = <a href={position.companyURL} rel="noopener noreferrer" target="_blank">{company}</a>;
                  }
                  company = <>: {company}</>;
                }

                return <div key={i}>
                  <div className="d-flex justify-content-between">
                    <h4>{position.title}{company}{position.location && <span className="location text-nowrap">{position.location}</span>}</h4>
                    <div className="timeframe text-nowrap">{position.timeframe}</div>
                  </div>
                  {position.highlights && <ul>
                    {position.highlights.map((highlight, j) => <li key={j} dangerouslySetInnerHTML={{__html: highlight}}></li>)}
                  </ul>}
                </div>;
              })}
            </section>

            <section className="competencies">
              <h3>Competencies</h3>
              <ul>
                {competencies.map((competency, i) => <li key={i}>
                  {competency.category}: <span dangerouslySetInnerHTML={{__html: competency.value}} />
                </li>)}
              </ul>
            </section>

            <section className="education">
              <h3>Education</h3>

              <div className="d-flex justify-content-between">
                <h4>Ph. D. Bioinformatics <span className="font-italic">Georgia Institute of Technology</span> <span className="location text-nowrap">Atlanta, GA</span></h4>
                <div className="timeframe text-nowrap">Dec 2005</div>
              </div>
              <ul>
                <li>Major contributor to designing and writing successful $1M NIH R-01 grant proposal.</li>
                <li>TI:GER fellowship - an interdisciplinary program (PhD / JD / MBA) focused on technology commercialization</li>
              </ul>

              <div className="d-flex justify-content-between">
                <h4>B. S. Biology <span className="font-italic">Houghton College</span> <span className="location text-nowrap">Houghton, NY</span></h4>
                <div className="timeframe text-nowrap">May 2001</div>
              </div>
            </section>

            <section className="about">
              <h3>About</h3>
              <p>Enjoy spending time with my wife and 3 sons, DIY, woodworking,board games, ultimate frisbee, and slacklining.</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ResumePage;
