export const competencies = [
  {
    category: 'Frameworks / tooling',
    value: '<strong>Angular, React</strong>, Cordova, <strong>Redux, Observables (RxJS)</strong>, Express, WebSockets, SASS',
  },
  {
    category: 'Programming',
    value: '<strong>TypeScript</strong>, JavaScript, <strong>Node.js</strong>, Objective-C / Swift, Java, Python, C++, Perl, SQL, OOP, TDD, code coverage',
  },
  {
    category: 'Databases',
    value: '<strong>PostgreSQL</strong>, MySQL, MongoDB, SQLite',
  },
  {
    category: 'Infrastructure',
    value: '<strong>Docker</strong>, AWS, Heroku, CI/CD (CircleCI, Jenkins)',
  },
  {
    category: 'Soft skills',
    value: 'innovation, <strong>critical thinking</strong>, collaboration, mentoring, <strong>excellent writing and communication</strong>, culture-building, interviewing',
  },
  {
    category: 'Other',
    value: 'responsive design, <strong>expert analytical abilities</strong>, data science, visualization, agile methodology, graphic design',
  },
];
