export const positions = [
  {
    title: 'Developer Lead',
    company: 'FIX',
    companyURL: 'https://www.eatfix.com',
    location: 'Memphis, TN',
    timeframe: '2019 - 2020',
    highlights: [
      'Rewrote front-end application in React (redux + observables).',
    ],
  },
  {
    title: 'Senior Software Engineer',
    company: 'DroneDeploy',
    companyURL: 'https://www.dronedeploy.com',
    location: 'San Francisco, CA',
    timeframe: '2016 - 2019',
    highlights: [
      'Full-stack experience maintaining and architecting novel drone flight capabilities that involved Docker-powered GraphQL APIs consumed by web / mobile devices (Cordova) and interfaced with iOS / Android drone SDKs.',
      'Optimized flight planning, asynchronous map rendering and interaction, observable telemetry streams, JavaScript / drone API interface, offline flight, and data synchronization.',
      'Improved drone flight reliability and image capture.',
    ],
  },
  {
    title: 'Senior Software Engineer',
    company: 'Questis',
    companyURL: 'https://www.myquestis.com/',
    location: 'Charleston, SC',
    timeframe: '2014 - 2016',
    highlights: [
      'Architected and launched financial wellness application from the ground up.',
      'Leveraged AWS lambda to aggregate and synchronize user\'s financial transactions in real-time.',
      'Mentored, trained, on-boarded new talent.',
    ],
  },
  {
    title: 'Entrepreneur / Bioinformatician',
    timeframe: '2013 - present',
    highlights: [
      'Authored <a href="https://auditrocket.com/app" class="font-italic" rel="noopener noreferrer" target="_blank">AuditRocket</a>: web application that makes auditing financial statements 10 times faster (<a href="https://auditrocket.com/app" rel="noopener noreferrer" target="_blank">https://auditrocket.com/app</a>).',
      'Led team of bioinformaticians to develop the next generation of MiST: an open-source project for elucidating and documenting signal transduction proteins in over 120,000 microbial genomes and their 500M proteins (<a href="https://mistdb.com" rel="noopener noreferrer" target="_blank">https://mistdb.com</a>, <a href="https://github.com/biowonks" rel="noopener noreferrer" target="_blank">https://github.com/biowonks/projects</a>).',
      'Created <a href="https://releto.com" class="font-italic" rel="noopener noreferrer" target="_blank">Releto</a>: real-time, collaborative management tool for hospitality professionals (<a href="https://releto.com" rel="noopener noreferrer" target="_blank">https://releto.com</a>).',
      'Produced <span class="font-italic">Zenphonik</span>: online, music education tool with an emphasis on ear-training',
    ],
  },
  {
    title: 'Founder',
    company: 'Agile Genomics',
    location: 'Charleston, SC',
    timeframe: '2008 - 2015',
    highlights: [
      '<span class="font-italic">Raised $350,000 in seed capital</span> (2 NIH SBIR grants and 2 matching grants from SCRA).',
      'Published 14 articles in high-impact, scientific journals; 6 manuscripts have been cited more than 100 times.',
      'Created <span class="font-italic">AlignShop</span>: highly-interactive tool for analyzing and visualizing critical patterns shared by biological sequences.',
      'Developed a tool that predicts thermodynamically stable primers for amplifying DNA.',
      'Built <a href="http://seqdepot.net" class="font-italic" rel="noopener noreferrer">SeqDepot</a>: sequence and feature database of over 30M proteins and 300M features (<a href="http://seqdepot.net" rel="noopener noreferrer">http://seqdepot.net</a>).',
      'Managed business in its entirety: fund-raising, grant compliance, marketing, hiring, payrool, and annual reports.'
    ],
  },
  {
    title: 'Postdoctoral Fellow',
    company: 'University of Tennessee / Oak Ridge National Laboratory',
    location: 'Knoxville, TN',
    timeframe: '2006 - 2008',
    highlights: [
      'Built <a href="https://mistdb.com" class="font-italic" rel="noopener noreferrer" target="_blank">MIST</a>: a database of bacterial sensor proteins widely used by the scientific community,',
      'Collaborated with researchers worldwide to annotate signal transduction found in bacteria.',
    ],
  },
];
